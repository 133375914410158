import React from 'react';
import styled from 'styled-components';

// Components
import { Col } from '../global/col';
import { Row } from '../global/row';
import Container from '../global/container';

const Text = styled.div`
  text-align: center;

  padding: ${props => props.padding};

  max-width: 830px;
  margin: 0 auto;

  & a {
    position: relative;
    color: #934e2a;
  }

  a:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: -2px;
    left: 0;
    background-color: #934e2a;
  }

  @media (max-width: 767px) {
    padding: ${props => props.mobilePadding};

    & h2 {
      font-size: 16px;
    }
  }
`;

const HeroText = ({ text, padding, mobilePadding }) => {
  return (
    <Container>
      <Row justifyContent="center">
        <Col col={12}>
          <Text
            padding={padding}
            mobilePadding={mobilePadding}
            dangerouslySetInnerHTML={{
              __html: text.html,
            }}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default HeroText;
